.wrapper {
    position: relative;
    width: 100%;
    background-color: var(--blue);
    margin-bottom: 70px;
}

.wrapper .firstBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid var(--white);
    gap: 10px;
}

.wrapper .firstBox .logo {
    height: fit-content;
}


.wrapper .firstBox .logo img {
    width: 130px;
}

.wrapper .firstBox .search {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}

.wrapper .firstBox .mobileSearch {
    display: none;
}

.wrapper .firstBox .search input,
.wrapper .firstBox .mobileSearch input {
    width: 100%;
    font-size: 16px;
    transition: all 0.3s linear;
    border: 1px solid var(--blue);
    transition: all 0.3s linear;
}

.wrapper .firstBox .search .searchBtn,
.wrapper .firstBox .mobileSearch .searchBtn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--darkBlue);
    color: var(--white);
    height: 100%;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.wrapper .firstBox button {
    background: none;
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 2px;
    display: flex;
    align-items: center;
    transition: all 0.3s linear;
    gap: 5px;
    padding: 6px;
    min-width: 130px;
    font-size: 14px;
}

.wrapper .firstBox button:hover {
    background-color: var(--white);
    color: var(--blue);
    transition: all 0.3s linear;
}

.wrapper .firstBox .toggleSearch {
    display: none;
}

.wrapper .firstBox .toggleSearch button {
    background: none;
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 2px;
    display: flex;
    align-items: center;
    transition: all 0.3s linear;
    gap: 5px;
    padding: 6px;
    min-width: fit-content;
    font-size: 13px;
}

.wrapper .firstBox .toggleSearch svg {
    color: var(--white);
}

.wrapper .secondBox {
    position: relative;
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
    min-height: 50px;
}

.wrapper .secondBox .wrap {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
}

.wrapper .secondBox svg {
    color: var(--white);
    cursor: pointer;
    transition: all 0.5s linear;
}

.wrapper .secondBox .wrap a {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 3px;
    font-weight: 500;
    gap: 5px;
    min-width: fit-content;
    margin: 0 0 0 7px;
    transition: all 0.3s linear;
}


.wrapper .secondBox .toggle {
    position: absolute;
    right: 15px;
    padding: 5px;
    border-radius: 5px;
    display: none;
    cursor: pointer;
}

.wrapper .secondBox .toggle svg {
    font-size: 32px;
    color: var(--white);
}

.wrap .firstBox .signup button {
    display: block;
}

/* Media Queries */
@media only screen and (max-width: 850px) {
    .wrapper .secondBox .toggle {
        display: block;
    }

    .wrapper .secondBox .wrap a,
    .wrapper .firstBox button {
        display: none;
    }

    .wrapper .firstBox .mobileSearch {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        top: 160px;
    }

    .wrapper .firstBox .mobileSearch input {
        width: 100%;
    }

    .wrapper .firstBox .search {
        display: none;
    }

    .wrapper .firstBox .toggleSearch {
        display: block;
    }

    .wrapper .firstBox .logo img {
        width: 100px;
    }
}

@media only screen and (max-width: 500px) {
    .wrapper .box {
        width: 100%;
    }

}