.wrapper {
    position: relative;
    width: 100%;
    background-color: var(--darkBlue);
}

.wrapper .box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    width: 90%;
    margin: 0 auto;
}

.wrapper .box .right {
    display: flex;
}

.wrapper .box .right a {
    border-left: 1px solid rgba(238, 238, 238, 0.664);
    font-family: var(--secondFont);
    font-size: 13px;
    padding: 5px 10px;
    transition: all 0.3s linear;
}

.wrapper .box .right a:last-of-type {
    border-left: none;
}

.wrapper .box .right a:hover {
    color: #222;
    transition: all 0.3s linear;
}

.wrapper .box button {
    color: var(--blue);
    background-color: var(--white);
    font-weight: 600;
    min-width: fit-content;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    padding: 10px;
    font-size: 13px;
    transition: all 0.3s linear;
    height: 85px;
}


@media only screen and (max-width: 500px) {

    .wrapper .box {
        width: 100%;
    }

    .wrapper .box .right a {
        font-size: 12px;
        padding: 5px 5px;
    }

    .wrapper .box button {
        padding: 6px;
        font-size: 11px;
        margin-left: 10px;
    }
}