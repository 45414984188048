* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--mainFont);
}

.wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #F6F6F6;
  overflow-x: hidden;
}



.App {
  width: 90%;
  position: relative;
  margin: 0 auto;
}

:root {
  --blue: #00aabc;
  --seal: #dff6f0;
  --white: #FFFFFF;
  --black: #000000;
  --darkBlue: #008896;
  --mainFont: "Tajawal", sans-serif;
  --secondFont: "Cairo", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--secondFont);
  font-weight: bold;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3,
h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: var(--white);
}

ul li {
  list-style: none;
}

body {
  background-color: var(--color5);
  overflow-x: hidden;
}

*,
html,
body,
main {
  direction: rtl;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  font-family: var(--secondFont);
  font-size: 15px;
}

input,
textarea,
select {
  outline: none;
  border: 1px solid #DDD;
  padding: 8px;
  padding-right: 12px;
  border-radius: 2px;
  font-size: 15px;
  resize: none;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #979797;
}

select:disabled {
  color: #707070;
}

svg {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .App {
    width: 95%;
  }
}